<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getPartsRegistry.name"
                    :avatarData="getPartsRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    :title="getPartsRegistry.name"
                    :items="getPartsTable.items"
                    :headers="headers"
                    :length="getPartsTable.pages"
                    :page="getPartsTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getPartsSearchFields.searchFields"
                    :loading="getPartsTable.loading"
                >
                    <Columns slot="columns" :items="getPartsTable.items" />
                </Table>
                <Modal
                    :title="$t('parts:createPart')"
                    :open="open"
                    :height="'550'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                @click="addPart"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('parts:addParts') }}</span>
                    </v-tooltip>
                </div></v-col
            >
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from './../../../components/Registries/Parts/Table/Columns'
import AEContent from './../../../components/Registries/Parts/Modal/Content'
import Buttons from './../../../components/Registries/Parts/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('parts:manufacturerIndex'),
                    value: 'manufacturerIndex',
                    width: '16%',
                    showSearch: true,
                },
                {
                    text: this.$t('parts:name'),
                    value: 'name',
                    width: '36%',
                    showSearch: true,
                },
                {
                    text: this.$t('parts:manufacturer'),
                    value: 'manufacturer',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('parts:model'),
                    value: 'model.name',
                    width: '12%',
                    showSearch: true,
                },
                {
                    text: this.$t('parts:substitute'),
                    value: 'substitute',
                    width: '9%',
                    align: 'left',
                },
                {
                    text: this.$t('parts:status'),
                    value: 'status.name',
                    width: '100px',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getPartsTable',
            'getPartsSearchFields',
            'getPartsRegistry',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchPartsTable', 'fetchRegistriesTable']),

        ...mapMutations([
            'clearPartsModal',
            'clearPartsErrors',
            'setPartsTable',
            'setPartsSearch',
            'clearPartsTable',
        ]),
        closeModal() {
            this.open = false
            this.clearPartsModal()
            this.clearPartsErrors()
        },
        addPart() {
            this.fetchRegistriesTable({
                select: 'icon url',
                where: { url: 'models' },
            })
            this.open = true
        },
        setSearchFields(searchFields) {
            this.setPartsSearch(searchFields)
        },
        setSearch(search) {
            this.setPartsTable({ search, page: 1 })
            this.fetchPartsTable()
        },
        setPage(page) {
            this.setPartsTable({ page })
            this.fetchPartsTable()
        },
        setSort(sort) {
            this.setPartsTable({ sort })
            this.fetchPartsTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearPartsTable()
            this.fetchPartsTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/parts/:id')
            store.commit('clearPartsTable')
        store.dispatch('fetchPartsTable').then(() => next())
    },
}
</script>
